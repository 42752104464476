
import React from 'react';
import MenuAppBar from './../../Components/Navbar/navbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PdfLogo from './../../images/pdf.png';
import { BsBoxArrowInDown } from "react-icons/bs";
import Chalan from './../../components/chalanCopy';
import axios from 'axios';
import { saveAs } from 'file-saver';
import FirebaseApp from "./../../config/firebase";
import { navigate } from 'gatsby'; //import navigate from gatsby
import { Link } from "gatsby"
import { Accordion, Card, Button } from 'react-bootstrap';
import { GrFormDown } from "react-icons/gr";
import { RiUploadCloudFill, RiDownload2Line } from "react-icons/ri";
import EasypasiaLogo from "./../../images/easypaisa.png"
import Jazzcashlogo from "./../../images/jazzcashlogo.png"
import './index.css'
let database = FirebaseApp.database().ref("/");


export default class ChalanPage extends React.Component {
    constructor() {
        super()
        this.state = {
            name: "suneel kumar",
            price1: "52465324",
            price2: 544564,
            receiptId: "one",
            appliedCourse: []
        }
    }


    componentDidMount() {
        let arr = []
        FirebaseApp.auth().onAuthStateChanged(user => {
            if (user && user.emailVerified) {
                database.child("students/" + user.uid + "/" + "Applied/").on('child_added', res => {
                    let data = res.val()
                    arr.push(data)
                    this.setState({ appliedCourse: arr })
                })
                //   navigate('/courses'); //navigate to edit page
            }
            else {
                navigate('/'); //navigate to edit page

            }
        })
    }

    createAndDownloadPdf = (data) => {
        let obj = {
            name: data.name,
            fname: data.fatherName,
            nic: data.nicNumber,
            course: data.course,
            fees: data.admitionFee
        }
        axios.post('https://chalangenerator.herokuapp.com/createchalan', obj)
            .then(() => axios.get('https://chalangenerator.herokuapp.com/chalan-pdf', { responseType: 'blob' }))
            .then((res) => {
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                saveAs(pdfBlob, 'newPdf.pdf');
            })
    }




    render() {
        let { appliedCourse } = this.state
        return (
            <div >
                <MenuAppBar />
                <Container fluid>
                    {appliedCourse.length !== 0 ?
                        appliedCourse.map((val, i) => {
                            return (
                                <div className="_accordionDiv">
                                    <Row className="justify-content-center">
                                        <Col lg="10">
                                            <Accordion >
                                                <Card className="_chalan_list">
                                                    <Card.Header className="_accordionToggle">
                                                        <Accordion.Toggle eventKey="0" className="_accordionToggleDiv">
                                                            <div className="_courseTitle">{i + 1}. {val.course}</div>
                                                            <div><GrFormDown size="30" color="grey" /></div>
                                                        </Accordion.Toggle>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body >
                                                            <button className="_admitCardDownloadBtn" onClick={() => this.createAndDownloadPdf(val)}><RiDownload2Line size="25" color="grey" /></button>
                                                            <div style={{ margin: "auto", marginTop: "20px" }}>
                                                                <Chalan data={val} />

                                                            </div>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </div>
                                )
                        }) : <div className="_empty_applied_Course"> <h5>you did not applied any course yet!!</h5><Link to="/courses"><button>Apply Now</button></Link> </div>}

                </Container>
            </div>
        )
    }
}